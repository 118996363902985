import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoteModal from './modal/noteModal';
import Note from './note';
import './board.css';
import Category from './category';
import { CategoryData, NoteData } from './types';
import { getApi } from '../Util/apiController';

interface BoardProps {
    id?: number;
}

const Board: React.FC<BoardProps> = ({ id = 0 }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentNote, setCurrentNote] = useState<NoteData | undefined>(undefined);
    const [notes, setNotes] = useState<NoteData[]>([]);
    const [categories, setCategories] = useState<CategoryData[]>([]);
    const [currentCategoryId, setCurrentCategoryId] = useState<number>(id);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setCurrentCategoryId(id);
    }, [id]);

    useEffect(() => {
        const fetchNotesAndCategories = async () => {
            try {
                const notesResponse = await getApi('notes');
                const categoriesResponse = await getApi('categories');
                setNotes(notesResponse.data);
                setCategories(categoriesResponse.data);
            } catch (error) {
                console.error('Error fetching notes and categories:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotesAndCategories();
    }, []);

    const handleCreateOrUpdateNote = (note: NoteData, isCreate: boolean) => {
        if (isCreate) {
            setNotes(prev => [...prev, note]);
        } else {
            setNotes(prev => prev.map(n => n.id === note.id ? note : n));
        }
        closeModal();
    };

    const openModal = (note?: NoteData) => {
        setCurrentNote(note);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentNote(undefined);
    };

    const handleCategoryClick = (categoryId: number) => {
        navigate(`/category/${categoryId}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='board'>
            {isModalOpen && (
                <NoteModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onAction={handleCreateOrUpdateNote}
                    existingNote={currentNote}
                />
            )}
            <div className='boardContent'>
                {notes.filter(note => note.category.id === currentCategoryId).map((note, index) => (
                    <div className="boardItem">
                        <Note key={index} note={note} onClick={() => openModal(note)} />
                    </div>
                ))}
                {currentCategoryId === 0 && categories.map((category, index) => (
                    <div className="boardItem">
                        <Category key={index} category={category} onClick={() => handleCategoryClick(category.id || 0)} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Board;
