// BackButton.js
import React from 'react';

const BackButton = ({ onClick }: { onClick: () => void }) => (
    <div className='backButton'>
        <button type="button" onClick={onClick} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
            <img src={`${process.env.PUBLIC_URL}/assets/backbutton.svg`} alt="Arrow Left" width="24" height="24" />
        </button>
        <div>Retour</div>
    </div>
);

export default BackButton;
