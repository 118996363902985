import React from "react";
import "./searchbar.css";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import BackHomeButton from "./buttons/backHomeButton";

interface SearchbarProps {
  id: number;
}

const Searchbar: React.FC<SearchbarProps> = ({ id }) => {
  const navigate = useNavigate();

  return (
    <div className="top">
      <div className="searchbar-bg">
        <div className="searchbar">
          <FontAwesomeIcon className="icon" icon={faMagnifyingGlass} />
          <input placeholder="Search..."></input>
        </div>
      </div>
      {id !== 0 && <BackHomeButton onClick={() => navigate("/")} />}
    </div>
  );
};

export { Searchbar };
