import React, { useState, useEffect, ChangeEvent, FC } from "react";
import axios from "axios";
import './inputs.css';
import InputGroup from "./input";
import { getApi, postApi } from "../../Util/apiController";

interface Keyword {
  id: number;
  name: string;
}

interface KeywordsComponentProps {
  addKeyword: (name: string) => Keyword;
  onKeywordsChange: (keywords: Keyword[]) => void;
}

const KeywordsComponent: FC<KeywordsComponentProps> = ({
  addKeyword,
  onKeywordsChange,
}) => {
  const [keywords, setKeywords] = useState<number[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Keyword[]>([]);
  const [allKeywords, setAllKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await getApi('key-words');
        setSuggestions(response.data);
        setAllKeywords(response.data);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      }
    };
    fetchKeywords();
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    const filteredSuggestions = allKeywords.filter((kw) =>
      kw.name.toLowerCase().includes(value.toLowerCase())
    );

    if (!allKeywords.some((kw) => kw.name === value) && value.trim() !== "") {
      filteredSuggestions.push({ id: -1, name: `Ajouter "${value}"` });
    }

    setSuggestions(filteredSuggestions);
  };

  const handleAddKeyword = async (kw: Keyword) => {
    if (kw.id === -1) {
      try {
        const response = await postApi('key-words', { name: inputValue });
        const newKeyword = response.data;
        const updatedKeywords = [...keywords, newKeyword.id];
        setKeywords(updatedKeywords);
        onKeywordsChange(
          allKeywords.filter((k) => updatedKeywords.includes(k.id))
        );
        setAllKeywords([...allKeywords, newKeyword]);
        setInputValue("");
        setSuggestions([...allKeywords, newKeyword]);
      } catch (error) {
        console.error("Error adding keyword:", error);
      }
    } else if (!keywords.includes(kw.id)) {
      const updatedKeywords = [...keywords, kw.id];
      setKeywords(updatedKeywords);
      onKeywordsChange(
        allKeywords.filter((k) => updatedKeywords.includes(k.id))
      );
      setInputValue("");
      setSuggestions(allKeywords);
    }
  };

  const handleRemoveKeyword = (id: number) => {
    const updatedKeywords = keywords.filter((kwId) => kwId !== id);
    setKeywords(updatedKeywords);
    onKeywordsChange(
      allKeywords.filter((k) => updatedKeywords.includes(k.id))
    );
  };

  return (
    <div className="keywords-component">
      <InputGroup
          label="Mot clés"
          name="title"
          id="title"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
        />
      <ul className="suggestions-list">
        {suggestions.map((kw) => (
          <li key={kw.id} onClick={() => handleAddKeyword(kw)} className="suggestion-item">
            {kw.name}
          </li>
        ))}
      </ul>
      <div className="selected-keywords">
        {keywords.map((id) => {
          const keyword = allKeywords.find((kw) => kw.id === id);
          return (
            <span key={id} className="keyword-tag">
              {keyword?.name}
              <button onClick={() => handleRemoveKeyword(id)} className="remove-btn">-</button>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default KeywordsComponent;
