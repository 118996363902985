// CategoryPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Board from '../Component/board';
import { Navbar } from '../Component/navbar';
import { Searchbar } from '../Component/searchbar';
import { Actionbar } from '../Component/actionbar';
import './page.css';
import { CategoryData } from '../Component/types';
import { getApi } from '../Util/apiController';

const CategoryPage: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const categoryId = id ? parseInt(id, 10) : 0;
    const [backgroundColor, setBackgroundColor] = useState<string>('var(--background-color)');
    const [categories, setCategories] = useState<CategoryData[]>([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getApi('categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const category = categories.find(cat => cat.id === categoryId);
        setBackgroundColor(category ? category.color : 'var(--background-color)');
    }, [categoryId, categories]);

    return (
        <div className='page' style={{ backgroundColor }}>
            <Searchbar id={categoryId} />
            <Navbar />
            <Actionbar />
            <Board id={categoryId} />
        </div>
    );
};

export default CategoryPage;
