import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Keyword, NoteData } from '../types';
import BackButton from '../buttons/backbutton';
import BrandedButton from '../buttons/brandedButton';
import './modal.css';
import ColorSelector from '../buttons/colorSelector';
import InputGroup from '../inputs/input';
import TextAreaGroup from '../inputs/textarea';
import KeywordsComponent from '../inputs/keywords';
import CategorySelect from '../buttons/categorySelector';
import { useSnackbar } from 'notistack';
import { patchApi, postApi } from '../../Util/apiController';

interface NoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAction: (note: NoteData, isCreate: boolean) => void;
  existingNote?: NoteData;
}

const NoteModal: React.FC<NoteModalProps> = ({ isOpen, onClose, onAction, existingNote }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState<NoteData>({
    id: 0, 
    title: '', 
    description: '', 
    link: '', 
    keyWord: [], 
    color: '', 
    category: { id: 0, name: '' }, 
    created_at: '', 
    updated_at: ''
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [existingKeywords, setExistingKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    if (existingNote) {
      setNote(existingNote);
      setEditMode(false);
    } else {
      setNote({
        id: 0, 
        title: '', 
        description: '', 
        link: '', 
        keyWord: [], 
        color: '', 
        category: { id: 0, name: '' }, 
        created_at: '', 
        updated_at: ''
      });
    }
  }, [existingNote]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const url = existingNote 
        ? `notes/${note.id}` 
        : 'notes';

      const method = existingNote ? patchApi : postApi;

      const response = await method(url, {
        title: note.title,
        description: note.description,
        link: note.link,
        color: note.color,
        keyWordsId: note.keyWord.map(keyword => keyword.id),
        categoryId: note.category.id
      });

      setError(null);
      enqueueSnackbar(existingNote ? 'Note updated successfully!' : 'Note created successfully!', { variant: 'success' });
      onAction(response.data, !existingNote);
      onClose();
    } catch (error: any) {
      console.error(existingNote ? 'Error updating note:' : 'Error creating note:', error);
      setError((existingNote ? 'Error updating note: ' : 'Error creating note: ') + (error.response?.data?.message || error.message));
      enqueueSnackbar(existingNote ? 'Error with note update :/' : 'Error with note creation :/', { variant: 'error' });
    }
  };

  const handleKeywordChange = (keywords: Keyword[]) => {
    setNote(prev => ({ ...prev, keyWord: keywords }));
  };

  const handleChange = (field: keyof NoteData, value: string) => {
    setNote(prev => ({ ...prev, [field]: value }));
  };

  const handleCategoryChange = (categoryId: number) => {
    setNote(prev => ({ ...prev, category: { id: categoryId, name: '' } }));
  };

  const handleColorChange = (color: string) => {
    setNote(prev => ({ ...prev, color }));
  };

  const toggleEditMode = () => {
    setEditMode(true);
  };

  if (!isOpen) return null;

  const formStyle = {
    background: !editMode ? note.color || 'white' : 'white',
    padding: '20px',
    borderRadius: '5px',
    color: '#333'
  };

  const addKeyword = (name: string): Keyword => {
    const newKeyword = { id: existingKeywords.length + 1, name };
    setExistingKeywords([...existingKeywords, newKeyword]);
    return newKeyword;
  };

  return (
    <div className='noteModal'>
      <form onSubmit={handleSubmit} style={formStyle}>
        {!editMode ? (
          <>
            <p>{note.title}</p>
            <p>{note.description}</p>
            <p>{note.link}</p>
            <p>{note.keyWord.map(kw => kw.name).join(', ')}</p>
            <BrandedButton text="Modifier mon idée" onClick={toggleEditMode} />
          </>
        ) : (
          <>
            <InputGroup 
              label="Title" 
              name="title" 
              id="title" 
              type="text" 
              autocomplete="off" 
              value={note.title} 
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)} 
              required={true} 
            />
            <TextAreaGroup 
              label="Description" 
              name="description" 
              id="description" 
              value={note.description} 
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange('description', e.target.value)} 
            />
            <InputGroup 
              label="Link" 
              name="link" 
              id="link" 
              type="text"
              autocomplete="off" 
              value={note.link} 
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('link', e.target.value)} 
            />
            <KeywordsComponent addKeyword={addKeyword} onKeywordsChange={handleKeywordChange} />
            <CategorySelect
              label="Category"
              title="categoryId"
              id="categoryId"
              value={note.category.id}
              onChange={handleCategoryChange}
            />
            <ColorSelector selectedColor={note.color} onColorChange={handleColorChange} />
            <BrandedButton text={existingNote ? "Mettre à jour ma note" : "Créer ma note"} type="submit" />
          </>
        )}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <BackButton onClick={onClose} />
      </form>
    </div>
  );
};

export default NoteModal;