import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './buttons.css';
import { getApi } from '../../Util/apiController';

interface Category {
  id: number;
  title: string;
}

interface CategorySelectProps {
  label: string;
  title: string;
  id: string;
  value: number;
  onChange: (value: number) => void;
}

const CategorySelector: React.FC<CategorySelectProps> = ({ label, title, id, value, onChange }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getApi('categories');
        setCategories(response.data);
        if (response.data.length > 0) {
          onChange(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []); // <-- tableau de dépendances vide

  return (
    <div className='categorySelector'>
      <label htmlFor={id}>{label}</label>
      <select
        title={title}
        id={id}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CategorySelector;
