import React from 'react';
import './inputs.css';

interface TextAreaProps {
  label: string;
  name: string;
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
}

const TextAreaGroup: React.FC<TextAreaProps> = ({ label, name, id, value, onChange, required = false }) => {
  return (
    <div className="textarea-group">
      <label className="label" htmlFor={id}>{label}</label>
      <textarea 
        name={name} 
        id={id} 
        className="textarea" 
        value={value} 
        onChange={onChange} 
        required={required} 
      />
    </div>
  );
};

export default TextAreaGroup;
