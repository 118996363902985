// HomePage.tsx
import React from 'react';
import { Navbar } from '../Component/navbar';
import { Actionbar } from '../Component/actionbar';
import Board from '../Component/board';
import { Searchbar } from '../Component/searchbar';

const HomePage: React.FC = () => {
    return (
        <div className='homePage'>
            <Searchbar id={0}/>
            <Navbar />
            <Actionbar />
            <Board id={0}/> 
        </div>
    );
};

export default HomePage;
