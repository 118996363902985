import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import CategoryPage from './Pages/CategoryPage';
import FeedbackPage from './Pages/FeedbackPage';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { MaterialDesignContent } from 'notistack'
import styled from '@emotion/styled';

import { Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './Context/AuthContext';

const ProtectedRoute = () => {
    const { isLoggedIn, isLoading } = useAuth();
    return isLoggedIn || isLoading ? <Outlet /> : <Navigate to="/login" replace />;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: 'var(--green)',
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: 'var(--red)',
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: 'var(--yellow)',
      },
      '&.notistack-MuiContent-info': {
        backgroundColor: 'var(--blue)',
      },
  }));

function App() {
    const handleClick = () => {
        enqueueSnackbar('This is a success message!', { variant: 'success' });
    };

    return (
        <AuthProvider>
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            autoHideDuration={3000}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
        >
            <div className='App'>
                <Router>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/category/:id" element={<CategoryPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/feedback" element={<FeedbackPage />} />
                    </Routes>
                </Router>
            </div>
        </SnackbarProvider>
        </AuthProvider>
    );
}

export default App;
