import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { LoginSchema, RegisterSchema } from '../Schemas/LoginSchema';
import { z } from 'zod';
import { useAuth } from '../Context/AuthContext';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [auth_type, setauth_type] = useState('');
  const [mode, setMode] = useState('login');
  const [, setError] = useState<string | null>(null);
  const authContext = useAuth();
  const navigate = useNavigate();
  const [, setErrors] = useState<z.ZodIssue[]>([]);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn && window.location.pathname === '/login') {
      navigate('/', { replace: true, state: { from: window.location.pathname } });
    }
  }, [isLoggedIn, navigate]);

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = RegisterSchema.safeParse({ email, password, auth_type });
    if (!result.success) {
      setErrors(result.error.issues);
      return;
    }
    setErrors([]); // Clear errors if submission is successful

    try {
      const res = await authContext.register(email, password, auth_type);
      if (!res) {
        alert("Identifiants mauvais");
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Erreur lors de la creation de compte', error);
      alert("Erreur lors de la creation de compte");
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = LoginSchema.safeParse({ email, password });
    if (!result.success) {
      setErrors(result.error.issues);
      return;
    }
    setErrors([]); // Clear errors if submission is successful

    try {
      const res = await authContext.login(email, password);
      if (!res) {
        alert("Identifiants incorrects");
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion', error);
      alert("Erreur lors de la connexion");
    }
  };

  const handleModeSwitch = () => {
    setMode(mode === 'login' ? 'register' : 'login');
    setError(null);
  };

  return (
    <div className='auth-page'>
    <div className="form-container">
      <p className="title">{mode === 'login' ? 'Welcome back' : 'Create an account'}</p>
      <form className="form" onSubmit={(e) => {
        e.preventDefault();
        mode === 'login' ? handleLogin(e) : handleRegister(e);
      }}>
        <input
          type="email"
          className="input"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
          <input
            type="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        {mode === 'register' && (
          <input
          type="text"
          className="input"
          placeholder="Auth-Type"
          value={auth_type}
          onChange={(e) => setauth_type(e.target.value)}
        />
        )}
        {mode === 'login' && (
          <p className="page-link">
            <span className="page-link-label">Forgot Password?</span>
          </p>
        )}
        <button className="form-btn" type="submit">{mode === 'login' ? 'Log in' : 'Sign up'}</button>
      </form>
      <p className="sign-up-label">
        {mode === 'login' ? "Don't have an account?" : 'Already have an account?'}
        <span className="sign-up-link" onClick={handleModeSwitch}>{mode === 'login' ? 'Sign up' : 'Log in'}</span>
      </p>
      <div className="buttons-container">
        {(
          <>
            <div className="google-login-button">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" x="0px" y="0px" className="google-icon" viewBox="0 0 48 48" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
                c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
                c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
                C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
                c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
                c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
              <span>Log in with Google</span>
            </div>
          </>
        )}
      </div>
    </div>
    </div>
  );
};

export default Auth;
