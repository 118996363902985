import React, { useState } from "react";
import './actionbar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import NoteModal from './modal/noteModal';
import CategoryModal from "./modal/categoryModal";

const Actionbar: React.FC = () => {
  const [showAddActions, setShowAddActions] = useState(false);
  const [showThemeActions, setShowThemeActions] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const toggleAddActions = () => setShowAddActions(!showAddActions);
  const toggleThemeActions = () => setShowThemeActions(!showThemeActions);

  const handleCreateNoteClick = () => setIsNoteModalOpen(true);
  const handleCloseNoteModal = () => setIsNoteModalOpen(false);
  const handleCreateNote = (note: any) => {
    setIsNoteModalOpen(false);
  };

  const handleCreateCategoryClick = () => setIsCategoryModalOpen(true);
  const handleCloseCategoryModal = () => setIsCategoryModalOpen(false);
  const handleCreateCategory = (note: any) => {
    console.log(note);
    setIsCategoryModalOpen(false);
  };

  return (
    <div className='actions'>
      <div className='action add'>
        <button className='roundbutton' onClick={toggleAddActions}>
          <FontAwesomeIcon className='icon-action' icon={faPlus} />
        </button><br/>
        {showAddActions && (
          <>
            <button className='button' onClick={handleCreateNoteClick}>créer une idée</button>
            <button className='button' onClick={handleCreateCategoryClick}>créer une catégorie</button>
          </>
        )}
      </div>
      <br />
      <div className='action theme'>
        <button className='roundbutton' onClick={toggleThemeActions}>
          <FontAwesomeIcon className='icon-action' icon={faImage} />
        </button><br/>
        {showThemeActions && (
          <>
            <button className='button'>Theme 1</button><br/>
            <button className='button'>Theme 2</button>
          </>
        )}
      </div>
      <NoteModal isOpen={isNoteModalOpen} onClose={handleCloseNoteModal} onAction={handleCreateNote} />
      <CategoryModal isOpen={isCategoryModalOpen} onClose={handleCloseCategoryModal} onCreateCategory={handleCreateCategory} />
    </div>
  );
};

export { Actionbar };
