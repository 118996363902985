import React, { useState } from 'react';
import axios from 'axios';
import { CategoryData } from '../types';
import ColorSelector from '../buttons/colorSelector';
import InputGroup from '../inputs/input';
import BackButton from '../buttons/backbutton';
import BrandedButton from '../buttons/brandedButton';
import './modal.css';
import { enqueueSnackbar } from 'notistack';
import { postApi } from '../../Util/apiController';

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateCategory: (category: CategoryData) => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({ isOpen, onClose, onCreateCategory }) => {
  const [category, setCategory] = useState<CategoryData>({ id: 0, title: '', color: 'var(--yellow)' });
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await postApi('categories', { title: category.title, color: category.color })
      setError(null);
      onCreateCategory(response.data);
      setCategory({ id: 0, title: '', color: 'var(--yellow)' });
      enqueueSnackbar('Category successfully created!', { variant: 'success' });
      onClose();
    } catch (error: any) {
      setError('Error creating category: ' + (error.response?.data?.message || error.message));
      enqueueSnackbar('Error with category creation :/', { variant: 'error' });
    }
  };

  const handleChange = (field: keyof CategoryData, value: string) => {
    setCategory(prev => ({ ...prev, [field]: value }));
  };

  const handleColorChange = (color: string) => {
    setCategory(prev => ({ ...prev, color }));
  };

  if (!isOpen) return null;

  return (
    <div className='categoryModal'>
      <form onSubmit={handleSubmit} style={{ background: 'white', padding: '20px', borderRadius: '5px', color: '#333' }}>
        <InputGroup
          label="Title (required)"
          name="title"
          id="title"
          type="text"
          autocomplete="off"
          value={category.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)}
          required={true}
        />
        <ColorSelector selectedColor={category.color} onColorChange={handleColorChange} />
        <BrandedButton text="Create Category" type="submit" />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <BackButton onClick={onClose} />
      </form>
    </div>
  );
};

export default CategoryModal;
