import React from 'react';
import { NoteData } from './types';

interface NoteProps {
    note: NoteData;
    onClick: (note: NoteData) => void;
}

const Note: React.FC<NoteProps> = ({ note, onClick }) => {
    const getTextColor = (color: string) => {
        switch (color) {
            case 'var(--yellow)':
            case 'var(--green)':
                return 'black';
            case 'var(--red)':
            case 'var(--blue)':
                return 'white';
            default:
                return 'black';
        }
    };

    return (
        <div className='note' style={{ backgroundColor: note.color, color: getTextColor(note.color) }}
             onClick={() => onClick(note)}>
            <h3>{note.title}</h3>
        </div>
    );
};

export default Note;