// ColorSelector.tsx
import React, { useState } from 'react';
import './buttons.css'

interface ColorSelectorProps {
  selectedColor?: string;
  onColorChange: (color: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ selectedColor = 'var(--yellow)', onColorChange }) => {
  const [currentColor, setCurrentColor] = useState(selectedColor);
  const colors = ['var(--red)', 'var(--blue)', 'var(--green)', 'var(--yellow)'];

  const handleColorChange = (color: string) => {
    setCurrentColor(color);
    onColorChange(color);
  };

  return (
    <div className='colorSelector'>
      {colors.map(color => (
        <button
          type="button"
          key={color}
          style={{
            backgroundColor: color,
            width: currentColor === color ? '50px' : '30px',
            height: currentColor === color ? '50px' : '30px',
            border: currentColor === color ? '3px solid var(--background-color)' : '1px solid var(--background-color)',
            margin: '5px',
            cursor: 'pointer',
          }}
          onClick={() => handleColorChange(color)}
        />
      ))}
    </div>
  );
};

export default ColorSelector;
