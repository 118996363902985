// Category.tsx
import React from 'react';
import { CategoryData } from './types';
import ArcText from './text/ArcText';

interface CategoryProps {
    category: CategoryData;
    onClick: (categoryId: number) => void;
}

const getCategoryClass = (color: string) => {
    switch (color) {
        case 'var(--yellow)':
            return 'category-yellow';
        case 'var(--green)':
            return 'category-green';
        case 'var(--red)':
            return 'category-red';
        case 'var(--blue)':
            return 'category-blue';
        default:
            return '';
    }
};

const Category: React.FC<CategoryProps> = ({ category, onClick }) => {
    return (
        <div className='categoryText'>
            <ArcText text={category.title} />
            <div className={`category ${getCategoryClass(category.color)}`}
                onClick={() => category.id && onClick(category.id)}>
            </div>
        </div>
    );
};

export default Category;
