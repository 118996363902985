import React from 'react';
import './inputs.css'

interface InputProps {
  label: string;
  name: string;
  id: string;
  type: string;
  autocomplete?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const InputGroup: React.FC<InputProps> = ({ label, name, id, type, autocomplete = "off", value, onChange, required = false }) => {
  return (
    <div className="input-group">
      <label className="label" htmlFor={id}>{label}</label>
      <input 
        autoComplete={autocomplete} 
        name={name} 
        id={id} 
        className="input" 
        type={type} 
        value={value} 
        onChange={onChange} 
        required={required} 
      />
    </div>
  );
};

export default InputGroup;
