import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ToggleLang from "./buttons/toggles/language";
import ToggleTheme from "./buttons/toggles/theme";
import "./navbar.css";
import { useAuth } from "../Context/AuthContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  const navbarStyle: React.CSSProperties = {
    // width: isOpen ? "110px" : "40px",
    // transition: "width 0.4s ease-in-out",
  };

  const hiddenStyle: React.CSSProperties = {
    // visibility: isOpen ? "visible" : "hidden",
    // opacity: isOpen ? "1" : "0",
    // pointerEvents: isOpen ? "auto" : "none",
    // transition: "opacity 0.3s linear",
  };

  const iconStyle = `rotate-icon ${isOpen ? "open" : ""}`;

  return (
    <div className="navbar" style={navbarStyle}>
      <button onClick={toggleMenu} className="manageMenu">
        <FontAwesomeIcon className={iconStyle} icon={faChevronRight} />
      </button>{" "}
      <div className="navbar-top">
        <img
          className="rethinkr-logo"
          src="/rethinkr.png"
          alt="rethinkr-logo"
          style={hiddenStyle}
        />
        <button style={hiddenStyle}>
		  <img src={`${process.env.PUBLIC_URL}/assets/home.svg`} alt="Home" width="24" height="24" />
        </button>
        <br />
        <button style={hiddenStyle}>
		  <img src={`${process.env.PUBLIC_URL}/assets/apps.svg`} alt="Apps" width="24" height="24" />
        </button>
        <br />
        <button style={hiddenStyle}>
		  <img src={`${process.env.PUBLIC_URL}/assets/feedback.svg`} alt="Feedback" width="24" height="24" />
        </button>
        <br />
        <img
          className="user-pp"
          src="https://static-00.iconduck.com/assets.00/profile-circle-icon-256x256-cm91gqm2.png"
          alt="pp"
          style={hiddenStyle}
        />
      </div>
      <div className="navbar-bot">
        {/* <div className="language" style={hiddenStyle}>
          <ToggleLang />
        </div>
        <div className="theme" style={hiddenStyle}>
          <ToggleTheme />
        </div> */}
        <button className="logout" onClick={handleLogout}>
          <FontAwesomeIcon className="iconStyle" icon={faDoorOpen} />
        </button>
        </div>
    </div>
  );
};

export { Navbar };
