import axios from "axios";

const url = "https://rethinkr-api.com/";
// const url = "http://localhost:3000/";

export const getUrl = () => url;

const getHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };
};

const api = axios.create({
  baseURL: url,
  headers: getHeaders()
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await axios.post(`${url}auth/refresh`, { refresh: refreshToken });
      const { accessToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
      return api(originalRequest);
    } catch (err) {
      console.error('Refresh token expired or invalid');
      window.location.href = '/login';
    }
  }
  return Promise.reject(error);
});

export async function getApi(route: string) {
  return await api.get(route);
}

export async function postApi(route: string, body: any) {
  const response = await api.post(route, body);
  return response.data;
}

export async function patchApi(route: string, body?: any) {
  return await api.patch(route, body);
}

export async function deleteApi(route: string, body?: any) {
  const response = await api.delete(route, {
    data: body,
  });
  return response.data;
}
