// ArcText.tsx
import React from 'react';
import './ArcText.css';

interface ArcTextProps {
    text: string;
}

const ArcText: React.FC<ArcTextProps> = ({ text }) => {
    const characters = text.split('');
    const arcLength = characters.length;

    return (
        <div className="arc-text">
            {characters.map((char, index) => (
                <span 
                    key={index} 
                    style={{ transform: `rotate(${(index - arcLength / 2) * 15}deg)` }}
                >
                    {char}
                </span>
            ))}
        </div>
    );
};

export default ArcText;
