import React from 'react';
import './buttons.css'

const BrandedButton = ({ text, onClick, type }: { text: string; onClick?: () => void; type?: "button" | "submit" }) => (
    <button 
        onClick={onClick}
        type={type || 'button'}
        className='brandedButton'>
        {text}
    </button>
);

export default BrandedButton;
