import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { postApi } from '../Util/apiController';

interface AuthContextProps {
    isLoggedIn: boolean;
    isLoading: boolean;
    login: (username: string, password: string) => Promise<boolean>;
    register: (email: string, password: string, auth_type: string) => Promise<boolean>;
    logout: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
    isLoggedIn: false,
    isLoading: true,
    login: () => Promise.resolve(false),
    register: () => Promise.resolve(false),
    logout: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString);
            localStorage.setItem("accessToken", user.accessToken);
            localStorage.setItem("refreshToken", user.refreshToken);
            setToken(user.accessToken);
        }
        setIsLoading(false);
    }, []);

    const login = async (username: string, password: string) => {
        const data = await postApi('auth/login', { username, password });
        if (data && data.accessToken) {
            localStorage.setItem('user', JSON.stringify({
              id: data.id,
              email: data.email,
              language: data.language,
              light_mode: data.light_mode,
              theme: data.theme,
              accessToken: data.accessToken,
              refreshToken: data.refreshToken
            }));
            setToken(data.accessToken);
            return true;
        } else {
            return false;
        }
    };

    const register = async (email: string, password: string, auth_type: string) => {
        const data = await postApi('auth/register', { email, password, auth_type });
        if (data && data.accessToken) {
            localStorage.setItem('user', JSON.stringify({
              id: data.id,
              email: data.email,
              language: data.language,
              light_mode: data.light_mode,
              theme: data.theme,
              accessToken: data.accessToken,
              refreshToken: data.refreshToken
            }));
            setToken(data.accessToken);
            return true;
        }
        return false;
    };

    const logout = () => {
        localStorage.clear();
        setToken(null);
        window.location.href = '/login';
    };

    const value = {
        isLoggedIn: !!token,
        isLoading,
        login,
        register,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
