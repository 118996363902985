// BackButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './buttons.css'

const BackHomeButton = ({ onClick }: { onClick: () => void }) => (
    <div>
        <button className='backHomeButton' type="button" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back to Home
        </button>
    </div>
);

export default BackHomeButton;
